import React, { useState, useEffect } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import pl from 'date-fns/locale/pl';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = { pl };
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const Grafik = () => {
    const [rezerwacje, setRezerwacje] = useState([]);
    const [flota, setFlota] = useState([]);
    const [firmy, setFirmy] = useState([]);
    const [osobyPrywatne, setOsobyPrywatne] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [editedData, setEditedData] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const rezerwacjeRes = await fetch('https://autosolgasystem.pl/django-app/dbview/rezerwacja_disp');
                const flotaRes = await fetch('https://autosolgasystem.pl/django-app/dbview/');
                const firmyRes = await fetch('https://autosolgasystem.pl/django-app/dbview/firma_disp');
                const osobyRes = await fetch('https://autosolgasystem.pl/django-app/dbview/osoba_disp');

                const [rezerwacjeData, flotaData, firmyData, osobyData] = await Promise.all([
                    rezerwacjeRes.json(),
                    flotaRes.json(),
                    firmyRes.json(),
                    osobyRes.json(),
                ]);

                setRezerwacje(rezerwacjeData);
                setFlota(flotaData);
                setFirmy(firmyData);
                setOsobyPrywatne(osobyData);
            } catch (error) {
                console.error('Błąd podczas pobierania danych:', error);
            }
        };

        fetchData();
    }, []);

    // Generowanie zdarzeń dla rezerwacji
    const events = rezerwacje
        .map((rezerwacja) => {
            const auto = flota.find((a) => a.id === rezerwacja.auto_id);
            const firma = firmy.find((f) => f.id === rezerwacja.firma_id);
            const osoba = osobyPrywatne.find((o) => o.id === rezerwacja.osoba_prywatna_id);

            const startDate = new Date(rezerwacja.odKiedy);
            const endDate = new Date(rezerwacja.doKiedy);
            const diffTime = Math.abs(endDate - startDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Różnica w dniach

            const title = `Auto: ${auto ? `${auto.nr_rejestracyjny} ${auto.model}` : 'Nieznane'} | ${firma ? firma.nazwa_firmy : osoba ? `${osoba.imie} ${osoba.nazwisko}` : 'Brak danych'}`;

            // Jeśli najem jest dłuższy niż 30 dni, tworzymy dwa zdarzenia: START i KONIEC
            if (diffDays > 30) {
                const startEvent = {
                    id: `start-${rezerwacja.id}`,
                    title: `START: ${title}`,
                    start: startDate,
                    end: startDate,
                    auto: auto ? `${auto.nr_rejestracyjny} ${auto.model}` : '',
                    klient: firma ? firma.nazwa_firmy : osoba ? `${osoba.imie} ${osoba.nazwisko}` : '',
                    odKiedy: rezerwacja.odKiedy,
                    doKiedy: rezerwacja.doKiedy,
                    type: 'rezerwacja',
                };

                const endEvent = {
                    id: `end-${rezerwacja.id}`,
                    title: `KONIEC: ${title}`,
                    start: endDate,
                    end: endDate,
                    auto: auto ? `${auto.nr_rejestracyjny} ${auto.model}` : '',
                    klient: firma ? firma.nazwa_firmy : osoba ? `${osoba.imie} ${osoba.nazwisko}` : '',
                    odKiedy: rezerwacja.odKiedy,
                    doKiedy: rezerwacja.doKiedy,
                    type: 'rezerwacja',
                };

                return [startEvent, endEvent];
            }

            // Dla najmów krótszych niż 30 dni, zwracamy normalne zdarzenie
            return {
                id: rezerwacja.id,
                title: title,
                start: startDate,
                end: endDate,
                auto: auto ? `${auto.nr_rejestracyjny} ${auto.model}` : '',
                klient: firma ? firma.nazwa_firmy : osoba ? `${osoba.imie} ${osoba.nazwisko}` : '',
                odKiedy: rezerwacja.odKiedy,
                doKiedy: rezerwacja.doKiedy,
                type: 'rezerwacja',
            };
        })
        .flat(); // Spłaszcz tablicę, aby połączyć zdarzenia START i KONIEC w jedną listę

    // Generowanie zdarzeń dla przeglądów i ubezpieczeń
    const przegladyIUbezpieczenia = flota
        .map((auto) => {
            const przegladEvent = {
                id: `przeglad-${auto.id}`,
                title: `Przegląd: ${auto.nr_rejestracyjny} ${auto.model}`,
                start: new Date(auto.data_przegladu),
                end: new Date(auto.data_przegladu),
                type: 'przeglad', // Typ zdarzenia
                auto: auto,
            };

            const ubezpieczenieEvent = {
                id: `ubezpieczenie-${auto.id}`,
                title: `Ubezpieczenie: ${auto.nr_rejestracyjny} ${auto.model}`,
                start: new Date(auto.data_ubezpieczenia),
                end: new Date(auto.data_ubezpieczenia),
                type: 'ubezpieczenie', // Typ zdarzenia
                auto: auto,
            };

            return [przegladEvent, ubezpieczenieEvent];
        })
        .flat(); // Spłaszcz tablicę

    // Połącz zdarzenia rezerwacji, przeglądów i ubezpieczeń
    const allEvents = [...events, ...przegladyIUbezpieczenia];

    // Funkcja do określania koloru zdarzenia
    const eventStyleGetter = (event) => {
        let style = {
            backgroundColor: '#3174ad', // Domyślny kolor dla rezerwacji
        };

        if (event.type === 'przeglad') {
            style.backgroundColor = '#808080'; // Szary dla przeglądów
        } else if (event.type === 'ubezpieczenie') {
            style.backgroundColor = '#8B4513'; // Brązowy dla ubezpieczeń
        }

        return { style };
    };

    const handleEventClick = (event) => {
        setSelectedEvent(event);
        if (event.type === 'rezerwacja') {
            setEditedData({
                auto: event.auto,
                klient: event.klient,
                odKiedy: event.odKiedy,
                doKiedy: event.doKiedy,
            });
        } else if (event.type === 'przeglad' || event.type === 'ubezpieczenie') {
            setEditedData({
                nr_rejestracyjny: event.auto.nr_rejestracyjny,
                data_przegladu: event.auto.data_przegladu,
                data_ubezpieczenia: event.auto.data_ubezpieczenia,
            });
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedEvent(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSave = async () => {
        if (selectedEvent.type === 'rezerwacja') {
            try {
                const response = await fetch(
                    `https://autosolgasystem.pl/django-app/dbview/update_rezerwacja/${selectedEvent.id}/`,
                    {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            odKiedy: editedData.odKiedy,
                            doKiedy: editedData.doKiedy,
                        }),
                    }
                );

                if (response.ok) {
                    console.log('Rezerwacja zaktualizowana pomyślnie.');
                    setRezerwacje((prev) =>
                        prev.map((rez) =>
                            rez.id === selectedEvent.id
                                ? { ...rez, odKiedy: editedData.odKiedy, doKiedy: editedData.doKiedy }
                                : rez
                        )
                    );
                } else {
                    console.error('Błąd podczas aktualizacji rezerwacji.');
                }
            } catch (error) {
                console.error('Wystąpił błąd podczas aktualizacji:', error);
            }
        } else if (selectedEvent.type === 'przeglad' || selectedEvent.type === 'ubezpieczenie') {
            try {
                const response = await fetch(
                    `https://autosolgasystem.pl/django-app/dbview/update_auto/${selectedEvent.auto.id}/`,
                    {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            data_przegladu: editedData.data_przegladu,
                            data_ubezpieczenia: editedData.data_ubezpieczenia,
                        }),
                    }
                );

                if (response.ok) {
                    console.log('Dane auta zaktualizowane pomyślnie.');
                    setFlota((prev) =>
                        prev.map((auto) =>
                            auto.id === selectedEvent.auto.id
                                ? { ...auto, data_przegladu: editedData.data_przegladu, data_ubezpieczenia: editedData.data_ubezpieczenia }
                                : auto
                        )
                    );
                } else {
                    console.error('Błąd podczas aktualizacji danych auta.');
                }
            } catch (error) {
                console.error('Wystąpił błąd podczas aktualizacji:', error);
            }
        }
        handleClose();
    };

    return (
        <Box className="main-content" style={{ margin: '20px' }}>
            <Typography variant="h4" gutterBottom align="center">
                Grafik Rezerwacji
            </Typography>
            <Box style={{ height: '80vh' }}>
                <Calendar
                    localizer={localizer}
                    events={allEvents}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: '100%' }}
                    views={['month', 'week', 'day']}
                    onSelectEvent={handleEventClick}
                    locale="pl"
                    eventPropGetter={eventStyleGetter} // Ustawienie kolorów zdarzeń
                    messages={{
                        next: 'Następny',
                        previous: 'Poprzedni',
                        today: 'Dziś',
                        month: 'Miesiąc',
                        week: 'Tydzień',
                        day: 'Dzień',
                        date: 'Data',
                        time: 'Czas',
                        event: 'Rezerwacja',
                        showMore: (total) => `+${total} więcej`,
                        weekday: ['Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob', 'Nd'],
                    }}
                />
            </Box>

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {selectedEvent?.type === 'rezerwacja' ? 'Edycja Rezerwacji' : 'Edycja Informacji o Aucie'}
                </DialogTitle>
                <DialogContent>
                    {selectedEvent?.type === 'rezerwacja' ? (
                        <>
                            <TextField
                                margin="dense"
                                label="Auto"
                                fullWidth
                                value={editedData.auto}
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                margin="dense"
                                label="Klient"
                                fullWidth
                                value={editedData.klient}
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                margin="dense"
                                label="Od Kiedy"
                                name="odKiedy"
                                type="date"
                                fullWidth
                                value={editedData.odKiedy}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                margin="dense"
                                label="Do Kiedy"
                                name="doKiedy"
                                type="date"
                                fullWidth
                                value={editedData.doKiedy}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </>
                    ) : (
                        <>
                            <TextField
                                margin="dense"
                                label="Numer Rejestracyjny"
                                fullWidth
                                value={editedData.nr_rejestracyjny}
                                InputProps={{ readOnly: true }}
                            />
                            <TextField
                                margin="dense"
                                label="Data Przeglądu"
                                name="data_przegladu"
                                type="date"
                                fullWidth
                                value={editedData.data_przegladu}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                margin="dense"
                                label="Data Ubezpieczenia"
                                name="data_ubezpieczenia"
                                type="date"
                                fullWidth
                                value={editedData.data_ubezpieczenia}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleSave} color="primary" variant="contained">
                        Zapisz
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Grafik;