import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    DialogContentText,
    TableHead,
    TableRow,
    Collapse,
    Paper,
    Typography,
    FormControl,
    MenuItem,
    Checkbox,
    Grid,
    FormControlLabel,
} from '@mui/material';
import './styles.css';
import { useConfirmDelete } from './ConfirmDelete';

const Rezerwacje = ({ onAdd, onDelete, onUpdate }) => {
    const [form, setForm] = useState({
        id: '',
        odKiedy: '',
        doKiedy: '',
        rozliczenie: '', // Dodane pole "Rozliczenie"
        autoId: '',
        firmaId: '',
        osobaPrywatnaId: '',
    });

    const [rozliczenieForm, setRozliczenieForm] = useState({
        data: '',
        kwota: '',
        status: '',
        metoda_platnosci: '',
    });

    const [addRozliczenie, setAddRozliczenie] = useState(false);
    const [open, setOpen] = useState(false);

    const [filtersVisible, setFiltersVisible] = useState(false);
    const [filters, setFilters] = useState({
        autoId: '',
        nazwa_firmy: '',
        nazwisko: '',
        search: '',
        dataOd: '',
        dataDo: '',
        dataRozliczenia: '', // Dodane pole "Data rozliczenia"
    });

    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [rezerwacje, setRezerwacje] = useState([]);
    const [flota, setFlota] = useState([]);
    const [firmy, setFirmy] = useState([]);
    const [osobyPrywatne, setOsobyPrywatne] = useState([]);

    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    useEffect(() => {
        fetch('https://autosolgasystem.pl/django-app/dbview/rezerwacja_disp')
            .then((response) => response.json())
            .then((data) => setRezerwacje(data))
            .catch((error) => console.error('Błąd pobierania rezerwacji:', error));

        fetch('https://autosolgasystem.pl/django-app/dbview/')
            .then((response) => response.json())
            .then((data) => setFlota(data))
            .catch((error) => console.error('Błąd pobierania floty:', error));

        fetch('https://autosolgasystem.pl/django-app/dbview/firma_disp')
            .then((response) => response.json())
            .then((data) => setFirmy(data))
            .catch((error) => console.error('Błąd pobierania firm:', error));

        fetch('https://autosolgasystem.pl/django-app/dbview/osoba_disp')
            .then((response) => response.json())
            .then((data) => setOsobyPrywatne(data))
            .catch((error) => console.error('Błąd pobierania osób prywatnych:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Jeśli wybrano firmę, wyczyść pole osoby prywatnej i odwrotnie
        if (name === 'firmaId') {
            setForm({ ...form, firmaId: value, osobaPrywatnaId: '' });
        } else if (name === 'osobaPrywatnaId') {
            setForm({ ...form, osobaPrywatnaId: value, firmaId: '' });
        } else {
            setForm({ ...form, [name]: value });
        }
    };

    const handleRozliczenieInputChange = (e) => {
        const { name, value } = e.target;
        setRozliczenieForm({ ...rozliczenieForm, [name]: value });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!form.firmaId && !form.osobaPrywatnaId) {
            setErrorMessage('Wybierz firmę lub osobę prywatną.');
            setErrorDialogOpen(true);
            return;
        }

        if (form.firmaId && form.osobaPrywatnaId) {
            setErrorMessage('Możesz wybrać tylko jedną opcję: firmę lub osobę prywatną.');
            setErrorDialogOpen(true);
            return;
        }

        const isVehicleAvailable = rezerwacje.every((rezerwacja) => {
            if (rezerwacja.auto_id !== form.autoId) return true;
            const existingStart = new Date(rezerwacja.odKiedy);
            const existingEnd = new Date(rezerwacja.doKiedy);
            const newStart = new Date(form.odKiedy);
            const newEnd = new Date(form.doKiedy);
            return newEnd < existingStart || newStart > existingEnd;
        });

        if (!isVehicleAvailable) {
            setErrorMessage(
                'Wybrany pojazd jest już zarezerwowany w tym terminie. Proszę wybrać inny pojazd lub zmienić daty.'
            );
            setErrorDialogOpen(true);
            return;
        }

        const payload = {
            odKiedy: form.odKiedy,
            doKiedy: form.doKiedy,
            rozliczenie: form.rozliczenie, // Dodane pole "Rozliczenie"
            autoId: form.autoId,
            firmaId: form.firmaId || undefined,
            osobaPrywatnaId: form.osobaPrywatnaId || undefined,
        };

        try {
            let rezerwacjaResponse;
            if (form.id) {
                // Aktualizuj istniejącą rezerwację
                rezerwacjaResponse = await fetch(`https://autosolgasystem.pl/django-app/dbview/update_rezerwacja/${form.id}/`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload),
                });
            } else {
                // Utwórz nową rezerwację
                rezerwacjaResponse = await fetch('https://autosolgasystem.pl/django-app/dbview/create_rezerwacja', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload),
                });
            }

            const rezerwacjaData = await rezerwacjaResponse.json();
            console.log('Rezerwacja zapisana:', rezerwacjaData);

            // Zaktualizuj stan rezerwacji
            if (form.id) {
                setRezerwacje((prevRezerwacje) =>
                    prevRezerwacje.map((rezerwacja) =>
                        rezerwacja.id === form.id ? { ...rezerwacja, ...rezerwacjaData } : rezerwacja
                    )
                );
            } else {
                setRezerwacje((prevRezerwacje) => [...prevRezerwacje, rezerwacjaData]);
            }

            // Jeśli checkbox "Dodaj rozliczenie" jest zaznaczony, utwórz fakturę
            if (addRozliczenie) {
                const rozliczeniePayload = {
                    data: rozliczenieForm.data,
                    kwota: rozliczenieForm.kwota,
                    status: rozliczenieForm.status,
                    metoda_platnosci: rozliczenieForm.metoda_platnosci,
                    rezerwacja_id: rezerwacjaData.id, // Użyj ID nowej rezerwacji
                };

                const fakturaResponse = await fetch('https://autosolgasystem.pl/django-app/dbview/create_faktura', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(rozliczeniePayload),
                });

                const fakturaData = await fakturaResponse.json();
                console.log('Faktura utworzona:', fakturaData);
            }

            // Resetuj formularz
            setForm({ id: '', odKiedy: '', doKiedy: '', rozliczenie: '', autoId: '', firmaId: '', osobaPrywatnaId: '' });
            setRozliczenieForm({ data: '', kwota: '', status: '', metoda_platnosci: '' });
            setOpen(false);
        } catch (error) {
            console.error('Błąd podczas zapisywania rezerwacji lub faktury:', error);
        }
    };

    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć tę rezerwację?');
        if (confirmed) {
            fetch(`https://autosolgasystem.pl/django-app/dbview/delete_rezerwacja/${id}/`, {
                method: 'DELETE',
            })
                .then((response) => response.json())
                .then(() => {
                    setRezerwacje((prevRezerwacje) => prevRezerwacje.filter((rezerwacja) => rezerwacja.id !== id));
                })
                .catch((error) => console.error('Błąd podczas usuwania rezerwacji:', error));
        }
    };

    const handleEditClick = (rezerwacja) => {
        setForm(rezerwacja);
        setOpen(true);
    };

    const filteredRezerwacje = rezerwacje.filter((rezerwacja) => {
        const auto = flota.find((auto) => auto.id === rezerwacja.auto_id);
        const firma = firmy.find((firma) => firma.id === rezerwacja.firma_id);
        const osoba = osobyPrywatne.find((osoba) => osoba.id === rezerwacja.osoba_prywatna_id);

        const matchesAuto =
            filters.autoId === '' ||
            (auto && `${auto.nr_rejestracyjny} ${auto.model}`.toLowerCase().includes(filters.autoId.toLowerCase()));

        const matchesFirmaOrOsoba =
            filters.search === '' ||
            (firma && firma.nazwa_firmy.toLowerCase().includes(filters.search.toLowerCase())) ||
            (osoba && osoba.nazwisko.toLowerCase().includes(filters.search.toLowerCase()));

        // Filtrowanie po datach
        const matchesDataOd = filters.dataOd === '' || new Date(rezerwacja.odKiedy) >= new Date(filters.dataOd);
        const matchesDataDo = filters.dataDo === '' || new Date(rezerwacja.doKiedy) <= new Date(filters.dataDo);
        const matchesDataRozliczenia = filters.dataRozliczenia === '' || new Date(rezerwacja.rozliczenie) <= new Date(filters.dataRozliczenia);

        return matchesAuto && matchesFirmaOrOsoba && matchesDataOd && matchesDataDo && matchesDataRozliczenia;
    });

    // Funkcja sprawdzająca dostępność samochodu w wybranym okresie
    const isAutoAvailable = (autoId) => {
        const newStart = new Date(form.odKiedy);
        const newEnd = new Date(form.doKiedy);

        return rezerwacje.every((rezerwacja) => {
            if (rezerwacja.auto_id !== autoId) return true; // Inne samochody nie są sprawdzane
            const existingStart = new Date(rezerwacja.odKiedy);
            const existingEnd = new Date(rezerwacja.doKiedy);
            return newEnd < existingStart || newStart > existingEnd;
        });
    };

    // Filtrowanie dostępnych samochodów
    const availableFlota = flota.filter((auto) => isAutoAvailable(auto.id));

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Najem
            </Typography>
            <Box className="button-group">
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj Najem
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setFiltersVisible(!filtersVisible)}
                >
                    Filtruj
                </Button>
            </Box>

            <Collapse in={filtersVisible}>
                <Box marginBottom={2} padding={2} border={1} borderColor="grey.300">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="search"
                                label="Firma lub osoba prywatna"
                                value={filters.search || ''}
                                onChange={handleFilterChange}
                                placeholder="Wpisz nazwę firmy lub nazwisko osoby"
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="autoId"
                                label="Auto (nr_rejestracyjny lub model)"
                                value={filters.autoId || ''}
                                onChange={handleFilterChange}
                                placeholder="Wpisz markę lub model auta"
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="dataOd"
                                label="Data od"
                                type="date"
                                value={filters.dataOd || ''}
                                onChange={handleFilterChange}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="dataDo"
                                label="Data do"
                                type="date"
                                value={filters.dataDo || ''}
                                onChange={handleFilterChange}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="dataRozliczenia"
                                label="Data rozliczenia"
                                type="date"
                                value={filters.dataRozliczenia || ''}
                                onChange={handleFilterChange}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{form.id ? 'Edytuj Rezerwację' : 'Dodaj Rezerwację'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            name="odKiedy"
                            label="Od Kiedy"
                            type="date"
                            value={form.odKiedy || ''}
                            onChange={handleInputChange}
                            required
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />

                        <TextField
                            name="doKiedy"
                            label="Do Kiedy"
                            type="date"
                            value={form.doKiedy || ''}
                            onChange={handleInputChange}
                            required
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />

                        <TextField
                            name="rozliczenie"
                            label="Rozliczenie"
                            type="date"
                            value={form.rozliczenie || ''}
                            onChange={handleInputChange}
                            required
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />

                        <FormControl fullWidth required>
                            <TextField
                                select
                                name="autoId"
                                label="Auto"
                                value={form.autoId || ''}
                                onChange={handleInputChange}
                                fullWidth
                            >
                                {availableFlota.map((pojazd) => (
                                    <MenuItem key={pojazd.id} value={pojazd.id}>
                                        {pojazd.nr_rejestracyjny} - {pojazd.model}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>

                        <FormControl fullWidth required>
                            <TextField
                                select
                                name="firmaId"
                                label="Firma"
                                value={form.firmaId || ''}
                                onChange={handleInputChange}
                                fullWidth
                            >
                                <MenuItem value="">Wybierz firmę</MenuItem>
                                {firmy.map((firma) => (
                                    <MenuItem key={firma.id} value={firma.id}>
                                        {firma.nazwa_firmy}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>

                        <FormControl fullWidth required>
                            <TextField
                                select
                                name="osobaPrywatnaId"
                                label="Osoba Prywatna"
                                value={form.osobaPrywatnaId || ''}
                                onChange={handleInputChange}
                                fullWidth
                            >
                                <MenuItem value="">Wybierz osobę prywatną</MenuItem>
                                {osobyPrywatne.map((osoba) => (
                                    <MenuItem key={osoba.id} value={osoba.id}>
                                        {osoba.imie} {osoba.nazwisko}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={addRozliczenie}
                                    onChange={(e) => setAddRozliczenie(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="Dodaj rozliczenie"
                        />

                        {addRozliczenie && (
                            <Box marginTop={2}>
                                <Typography variant="h6">Rozliczenie</Typography>
                                <TextField
                                    name="data"
                                    label="Data"
                                    type="date"
                                    value={rozliczenieForm.data || ''}
                                    onChange={handleRozliczenieInputChange}
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                />
                                <TextField
                                    name="kwota"
                                    label="Kwota"
                                    type="number"
                                    value={rozliczenieForm.kwota || ''}
                                    onChange={handleRozliczenieInputChange}
                                    required
                                    fullWidth
                                />
                                <FormControl fullWidth required>
                                    <TextField
                                        select
                                        name="status"
                                        label="Status"
                                        value={rozliczenieForm.status || ''}
                                        onChange={handleRozliczenieInputChange}
                                        fullWidth
                                    >
                                        <MenuItem value="paid">Opłacona</MenuItem>
                                        <MenuItem value="unpaid">Nieopłacona</MenuItem>
                                    </TextField>
                                </FormControl>
                                <FormControl fullWidth required>
                                    <TextField
                                        select
                                        name="metoda_platnosci"
                                        label="Metoda Płatności"
                                        value={rozliczenieForm.metoda_platnosci || ''}
                                        onChange={handleRozliczenieInputChange}
                                        fullWidth
                                    >
                                        <MenuItem value="card">Karta</MenuItem>
                                        <MenuItem value="cash">Gotówka</MenuItem>
                                        <MenuItem value="transfer">Przelew</MenuItem>
                                    </TextField>
                                </FormControl>
                            </Box>
                        )}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {form.id ? 'Zapisz' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
                <DialogTitle>Błąd w formularzu</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setErrorDialogOpen(false)} color="primary">
                        Zamknij
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Od Kiedy</TableCell>
                            <TableCell>Do Kiedy</TableCell>
                            <TableCell>Rozliczenie</TableCell>
                            <TableCell>Auto</TableCell>
                            <TableCell>Firma / Osoba Prywatna</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRezerwacje.map((rezerwacja) => {
                            const auto = flota.find((auto) => auto.id === rezerwacja.auto_id);
                            const firma = firmy.find((firma) => firma.id === rezerwacja.firma_id);
                            const osoba = osobyPrywatne.find((osoba) => osoba.id === rezerwacja.osoba_prywatna_id);

                            return (
                                <TableRow key={rezerwacja.id}>
                                    <TableCell>{rezerwacja.odKiedy}</TableCell>
                                    <TableCell>{rezerwacja.doKiedy}</TableCell>
                                    <TableCell>{rezerwacja.rozliczenie}</TableCell>
                                    <TableCell>
                                        {auto?.nr_rejestracyjny || 'Brak'} - {auto?.model || 'Brak'}
                                    </TableCell>
                                    <TableCell>
                                        {firma ? firma.nazwa_firmy : osoba ? `${osoba.imie} ${osoba.nazwisko}` : 'Brak'}
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEditClick(rezerwacja)} color="primary">
                                            Edytuj
                                        </Button>
                                        <Button onClick={() => handleDeleteClick(rezerwacja.id)} color="secondary">
                                            Usuń
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationDialog />
        </Box>
    );
};

export default Rezerwacje;