import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Grid,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Collapse,
} from '@mui/material';

const Faktury = () => {
    const [form, setForm] = useState({
        id: '',
        data: '',
        kwota: '',
        status: '',
        metoda_platnosci: '',
        rezerwacja_id: '',
    });
    const [open, setOpen] = useState(false);
    const [faktury, setFaktury] = useState([]);
    const [rezerwacje, setRezerwacje] = useState([]);
    const [auta, setAuta] = useState([]);
    const [firmy, setFirmy] = useState([]);
    const [osobyPrywatne, setOsobyPrywatne] = useState([]);
    const [openFilters, setOpenFilters] = useState(false);
    const [filters, setFilters] = useState({
        status: '',
        rezerwacja_id: '',
    });
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [sortBy, setSortBy] = useState('id'); // Domyślnie sortuj po ID

    useEffect(() => {
        // Pobierz rezerwacje
        fetch('https://autosolgasystem.pl/django-app/dbview/rezerwacja_disp')
            .then((response) => response.json())
            .then((data) => setRezerwacje(data))
            .catch((error) => console.error('Error fetching rezerwacje:', error));

        // Pobierz auta
        fetch('https://autosolgasystem.pl/django-app/dbview/')
            .then((response) => response.json())
            .then((data) => setAuta(data))
            .catch((error) => console.error('Error fetching auta:', error));

        // Pobierz firmy
        fetch('https://autosolgasystem.pl/django-app/dbview/firma_disp')
            .then((response) => response.json())
            .then((data) => setFirmy(data))
            .catch((error) => console.error('Error fetching firmy:', error));

        // Pobierz osoby prywatne
        fetch('https://autosolgasystem.pl/django-app/dbview/osoba_disp')
            .then((response) => response.json())
            .then((data) => setOsobyPrywatne(data))
            .catch((error) => console.error('Error fetching osoby prywatne:', error));

        // Fetching Faktury
        fetch('https://autosolgasystem.pl/django-app/dbview/faktura_disp')
            .then((response) => response.json())
            .then((data) => setFaktury(data))
            .catch((error) => console.error('Error fetching faktury:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const rezerwacjeZSzczegolami = rezerwacje.map((rezerwacja) => {
        const auto = auta.find((a) => a.id === rezerwacja.auto_id);
        const firma = firmy.find((f) => f.id === rezerwacja.firma_id);
        const osobaPrywatna = osobyPrywatne.find((o) => o.id === rezerwacja.osoba_prywatna_id);

        return {
            ...rezerwacja,
            auto,
            firma,
            osobaPrywatna,
        };
    });

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const payload = {
            data: form.data,
            kwota: form.kwota,
            status: form.status,
            metoda_platnosci: form.metoda_platnosci,
            rezerwacja_id: form.rezerwacja_id,
        };

        console.log('Payload:', payload);

        if (form.id) { // Aktualizacja (PUT)
            fetch(`https://autosolgasystem.pl/django-app/dbview/update_faktura/${form.id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setFaktury((prevFaktury) =>
                        prevFaktury.map((faktura) =>
                            faktura.id === form.id ? { ...faktura, ...data } : faktura
                        )
                    );
                    resetForm();
                })
                .catch((error) => console.error('Error updating faktura:', error));
        } else { // Tworzenie nowej faktury (POST)
            fetch('https://autosolgasystem.pl/django-app/dbview/create_faktura', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setFaktury((prevFaktury) => [...prevFaktury, data]);
                    resetForm();
                })
                .catch((error) => console.error('Error creating faktura:', error));
        }
    };

    const resetForm = () => {
        setForm({
            data: '',
            kwota: '',
            status: '',
            metoda_platnosci: '',
            rezerwacja_id: '',
        });
        setOpen(false);
    };

    const handleDeleteClick = (id) => {
        setSelectedInvoiceId(id);
        setOpenConfirmDialog(true);
    };

    const handleConfirmDelete = () => {
        if (selectedInvoiceId !== null) {
            fetch(`https://autosolgasystem.pl/django-app/dbview/delete_faktura/${selectedInvoiceId}/`, {
                method: 'DELETE',
            })
                .then(() => {
                    setFaktury((prevFaktury) =>
                        prevFaktury.filter((faktura) => faktura.id !== selectedInvoiceId)
                    );
                })
                .catch((error) => console.error('Error deleting faktura:', error));
        }
        setOpenConfirmDialog(false);
    };

    const handleCancelDelete = () => {
        setOpenConfirmDialog(false);
    };

    const handleEditClick = (faktura) => {
        setForm({
            id: faktura.id,
            data: faktura.data,
            kwota: faktura.kwota,
            status: faktura.status,
            metoda_platnosci: faktura.metoda_platnosci,
            rezerwacja_id: faktura.rezerwacja_id,
        });
        setOpen(true);
    };

    const filteredFaktury = faktury.filter((faktura) => {
        const matchesStatus = filters.status === '' || faktura.status.toLowerCase().includes(filters.status.toLowerCase());
        const matchesRezerwacja = filters.rezerwacja_id === '' || faktura.rezerwacja_id === filters.rezerwacja_id;
        return matchesStatus && matchesRezerwacja;
    });

    // Funkcja sortująca
    const sortFaktury = (faktury) => {
        return faktury.sort((a, b) => {
            if (sortBy === 'id') {
                return a.id - b.id; // Sortowanie po ID
            } else if (sortBy === 'data') {
                return new Date(a.data) - new Date(b.data); // Sortowanie po dacie
            }
            return 0;
        });
    };

    const sortedFaktury = sortFaktury(filteredFaktury);

    // Funkcja obliczająca liczbę dni od daty rozpoczęcia
    const obliczDniOdRozliczenia = (dataRozliczenia) => {
        const dzisiaj = new Date();
        const dataRozliczeniaObj = new Date(dataRozliczenia);
        const roznicaCzasu = dzisiaj - dataRozliczeniaObj;
        const roznicaDni = Math.floor(roznicaCzasu / (1000 * 60 * 60 * 24));
        return roznicaDni;
    };

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Rozliczenia
            </Typography>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Dodaj Rozliczenie
            </Button>
            <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpenFilters(!openFilters)}
            >
                Filtruj
            </Button>
            <Button
                variant="outlined"
                color="primary"
                onClick={() => setSortBy(sortBy === 'id' ? 'data' : 'id')}
                sx={{ marginLeft: 2 }}
            >
                Sortuj po {sortBy === 'id' ? 'Dacie' : 'ID'}
            </Button>

            {/* Filtry */}
            <Collapse in={openFilters} timeout="auto" unmountOnExit>
                <Box sx={{ marginTop: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Status Rozliczenia</InputLabel>
                                <Select
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                    fullWidth
                                >
                                    <MenuItem value="">Wszystkie</MenuItem>
                                    <MenuItem value="paid">Opłacona</MenuItem>
                                    <MenuItem value="unpaid">Nieopłacona</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Rezerwacja</InputLabel>
                                <Select
                                    name="rezerwacja_id"
                                    value={filters.rezerwacja_id}
                                    onChange={handleFilterChange}
                                    fullWidth
                                >
                                    <MenuItem value="">Wszystkie</MenuItem>
                                    {rezerwacje.map((rezerwacja) => (
                                        <MenuItem key={rezerwacja.id} value={rezerwacja.id}>
                                            {rezerwacja.odKiedy} - {rezerwacja.doKiedy}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>

            {/* Tabela */}
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data rozpoczęcia</TableCell>
                            <TableCell>Kwota</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Metoda Płatności</TableCell>
                            <TableCell>Rezerwacja</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedFaktury.map((faktura) => {
                            const dniOdRozliczenia = obliczDniOdRozliczenia(faktura.data); // Oblicz liczbę dni
                            const czyPrzeterminowane = dniOdRozliczenia > 28; // Sprawdź, czy minęło więcej niż 28 dni
                            const rezerwacja = rezerwacje.find((r) => r.id === faktura.rezerwacja_id);

                            return (
                                <TableRow
                                    key={faktura.id}
                                    style={{ backgroundColor: czyPrzeterminowane ? '#ffcccc' : 'inherit' }} // Kolorowanie na czerwono
                                >
                                    <TableCell>{faktura.data}</TableCell>
                                    <TableCell>{faktura.kwota}</TableCell>
                                    <TableCell>{faktura.status === 'paid' ? 'Opłacona' : 'Nieopłacona'}</TableCell>
                                    <TableCell>
                                        {faktura.metoda_platnosci === 'card' ? 'Karta' :
                                            faktura.metoda_platnosci === 'cash' ? 'Gotówka' : 'Przelew'}
                                    </TableCell>
                                    <TableCell>{rezerwacja?.odKiedy || 'Brak'}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEditClick(faktura)} color="primary">
                                            Edytuj
                                        </Button>
                                        <Button onClick={() => handleDeleteClick(faktura.id)} color="secondary">
                                            Usuń
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>{form.id ? 'Edytuj Fakturę' : 'Dodaj Rozliczenie'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="data"
                                    label="Data rozpoczęcia"
                                    type="date"
                                    value={form.data}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ marginTop: 1, marginBottom: 1 }} // Zwiększenie paddingu
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="kwota"
                                    label="Kwota"
                                    type="number"
                                    value={form.kwota}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                    sx={{ marginTop: 1, marginBottom: 1 }} // Zwiększenie paddingu
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
                                    <InputLabel>Status Rozliczenia</InputLabel>
                                    <Select
                                        name="status"
                                        value={form.status}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <MenuItem value="paid">Opłacona</MenuItem>
                                        <MenuItem value="unpaid">Nieopłacona</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
                                    <InputLabel>Metoda Płatności</InputLabel>
                                    <Select
                                        name="metoda_platnosci"
                                        value={form.metoda_platnosci}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <MenuItem value="card">Karta</MenuItem>
                                        <MenuItem value="cash">Gotówka</MenuItem>
                                        <MenuItem value="transfer">Przelew</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
                                    <InputLabel>Rezerwacja</InputLabel>
                                    <Select
                                        name="rezerwacja_id"
                                        value={form.rezerwacja_id || ''}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <MenuItem value="">Wybierz rezerwację</MenuItem>
                                        {rezerwacjeZSzczegolami.map((rezerwacja) => (
                                            <MenuItem key={rezerwacja.id} value={rezerwacja.id}>
                                                {rezerwacja.auto?.marka} {rezerwacja.auto?.model} - {rezerwacja.firma && (
                                                    <span>
                                                        {rezerwacja.firma.nazwa}
                                                    </span>
                                                )}
                                                {rezerwacja.osobaPrywatna && (
                                                    <span>
                                                        {rezerwacja.osobaPrywatna.imie} {rezerwacja.osobaPrywatna.nazwisko}
                                                    </span>
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {form.id ? 'Zapisz' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog do potwierdzenia usunięcia faktury */}
            <Dialog open={openConfirmDialog} onClose={handleCancelDelete}>
                <DialogTitle>Potwierdzenie usunięcia</DialogTitle>
                <DialogContent>
                    <Typography>Jesteś pewny, że chcesz usunąć tę fakturę?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                        Usuń
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Faktury;